import * as Sentry from "@sentry/react";
import { History } from "history";
import { useConfig } from "../../ConfigProvider";
import { useStateValue } from "../../StateManagement";
import { validateContact, validateMemberNumber } from "../../util/fieldValidator";
import { passwordlessStartDirect } from "../../util/passwordlessStartProxy";
import { shouldPasswordlessCallBeSkipped } from "../../util/shouldPasswordlessCallBeSkipped";
import { memberNumberDisplayStates, memberNumberTealiumLabel } from "../constants";
import { passwordlessFlowConstants } from "../dataConstants";
import * as actionTypes from "./actionTypes";

import { ContactType } from "../../initialState";
import {
  isEmail,
  isPhoneNumber,
  skipMemberNumberCheck,
  stripSpaces,
} from "../../util/contactUtilities";
import { useTealiumWrapper } from "../../util/TealiumWrapper";
import { setContactInSessionStorage } from "../../contactSessionStorage";

export const useActionCreator = () => {
  const { state, dispatch } = useStateValue();
  const config = useConfig();
  const constants = passwordlessFlowConstants(config);
  const { tealiumTrack } = useTealiumWrapper();

  const displayError = () => {
    dispatch({
      type: actionTypes.SHOW_ALERT,
      alertType: "error",
      title: constants.SYSTEM_ERROR_ALERT_TITLE,
      message: constants.SYSTEM_ERROR_ALERT_MESSAGE,
    });
  };

  const dispatchValidateForm = (contact: string, isValid: boolean) => {
    if (!isValid) {
      tealiumTrack("invalid_contact_details", { contact });
    }
    dispatch({
      type: actionTypes.VALIDATE_FORM,
      hasBeenValidated: true,
      isValid,
      validationMessage: constants.ENTER_CONTACT_DETAILS_VALIDATION_MESSAGE ?? "",
    });
  };

  function shouldDisplayMemberNumberForm(contact: string): boolean {
    const regex = new RegExp("@");
    const isAEmail = regex.test(contact);

    const contactWithoutSpaces = stripSpaces(contact);
    const isAPhone = isPhoneNumber(contactWithoutSpaces);

    const previousType = state.login.login.previousContactType;

    if (isAEmail) {
      dispatch({
        type: actionTypes.SET_CONTACT_TYPE,
        previousType: ContactType.EMAIL,
      });
      return false;
    }

    if (isAPhone) {
      dispatch({
        type: actionTypes.SET_CONTACT_TYPE,
        previousType: ContactType.PHONE,
      });
    }

    return isAPhone || previousType === ContactType.PHONE;
  }

  const contactChanged = (contact: string) => {
    dispatch({
      type: actionTypes.UPDATE_CONTACT,
      contact: contact,
      isValid: validateContact(contact, config),
      displayMemberNumberForm: shouldDisplayMemberNumberForm(contact),
    });
  };

  const rememberContactChanged = (rememberContact: boolean) => {
    tealiumTrack("checkbox_remember_contact", { rememberContact });
    dispatch({
      type: actionTypes.UPDATE_REMEMBER_CONTACT,
      rememberContact: rememberContact,
    });
  };

  const memberNumberChanged = (memberNumber: string) => {
    dispatch({
      type: actionTypes.UPDATE_MEMBER_NUMBER,
      memberNumber: memberNumber,
      isValid: validateMemberNumber(memberNumber),
    });
  };

  const validateForm = () => {
    const contact = state.login.login.contact;

    const isEmptyContact = contact === "";
    const isValid = validateContact(contact, config);

    dispatch({
      type: actionTypes.VALIDATE_FORM,
      hasBeenValidated: true,
      isValid,
      validationMessage:
        isEmptyContact || isValid ? "" : constants.ENTER_CONTACT_DETAILS_VALIDATION_MESSAGE,
    });
  };

  const validateMemberNumberInput = () => {
    const isValid = validateMemberNumber(state.login.login.memberNumber.memberNumber);

    if (!isValid) {
      tealiumTrack("invalid_member_number", {
        memberNumber: state.login.login.memberNumber,
      });
    }

    dispatch({
      type: actionTypes.MEMBER_NUMBER_VALIDATE_INPUT,
      hasBeenValidated: true,
      validationMessage: isValid ? "" : constants.ENTER_MEMBER_NUMBER_VALIDATION_MESSAGE,
      isValid,
    });
  };

  const fireTealiumEvent = () => {
    const displayStates = state.login.login.displayMemberNumberStates;
    const states = memberNumberDisplayStates;

    const startsWithAnOn = displayStates[0] === states.ON;
    const endsWithAnOff = displayStates[displayStates.length - 1] === states.OFF;

    if (startsWithAnOn && endsWithAnOff) {
      tealiumTrack("link_click", { memberNumber: memberNumberTealiumLabel });
    }
  };

  const requestPasswordlessStartDirect = (history: History, contact: string) => {
    const lastPasswordlessStartSuccessResultTime =
      state.login.login.lastPasswordlessStartSuccessResultTime;
    const moveToOtpScreen = () => {
      setContactInSessionStorage(contact);
      history.push("/otp");
    };

    fireTealiumEvent();

    if (shouldPasswordlessCallBeSkipped(lastPasswordlessStartSuccessResultTime ?? 0)) {
      moveToOtpScreen();
    } else {
      const updatedMemberNumberData = isEmail(state.login.login.contact)
        ? {
            ...state.login.login.memberNumber,
            memberNumber: "",
            isValid: false,
            hasBeenValidated: false,
            validationMessage: "",
          }
        : state.login.login.memberNumber;

      dispatch({
        type: actionTypes.START_CONTACT_SENDING,
        memberNumber: updatedMemberNumberData.memberNumber,
        memberNumberState: updatedMemberNumberData,
      });

      return passwordlessStartDirect(contact, config)
        .then(() => {
          dispatch({
            type: actionTypes.COMPLETE_CONTACT_SENDING,
          });
          moveToOtpScreen();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          Sentry.captureException(`$$$-passwordlessStartDirect-exception: ${JSON.stringify(err)}`);
          displayError();
        });
    }

    return Promise.resolve();
  };

  const submitForm = async (history: History, brand: string) => {
    const memberNumber = state.login.login.memberNumber?.memberNumber;
    let contact = stripSpaces(state.login.login.contact);
    let isContactValid = validateContact(contact, config);
    let isMemberNumberValid = validateMemberNumber(memberNumber);

    const displayPhoneNumberAuth = config.featureFlags.enablePhoneNumberAuth.isOn();

    if (displayPhoneNumberAuth && isPhoneNumber(contact)) {
      //If pressing enter before entering customer number, then we must exit out and validate the form
      if (!state.login.login.hasBeenValidated) {
        validateMemberNumberInput();
        validateForm();
        return;
      }

      if (!isMemberNumberValid && !skipMemberNumberCheck(brand)) {
        return validateMemberNumberInput();
      }
    }

    if (!isContactValid) {
      tealiumTrack("invalid_contact", {
        contact,
      });

      return dispatchValidateForm(contact, isContactValid);
    }

    tealiumTrack("submit_contact", {
      ...(contact && { contact }),
      ...(memberNumber && { memberNumber }),
    });
    return await requestPasswordlessStartDirect(history, contact);
  };

  return {
    contactChanged,
    rememberContactChanged,
    memberNumberChanged,
    validateForm,
    validateMemberNumberInput,
    submitForm,
  };
};
