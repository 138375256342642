import Copy from "@nib-components/copy";
import { Stack } from "@nib/layout";
import React, { useLayoutEffect, useState, JSX, useEffect } from "react";
import styled from "styled-components";
import {
  AlertComponent,
  DisplayBorder,
  Header,
  LinkButton,
  NoBorderForm,
  NoSpacingPrimaryButton,
  TextBoxComponent,
} from "../components";
import { AlertComponentProps } from "../components/AlertComponent";
import { useConfig } from "../ConfigProvider";
import { ContactUsComponent } from "../ContactUs";
import { State } from "../initialState";
import { BasicPageTemplate } from "../panels";
import { useStateValue } from "../StateManagement";
import { WheresMyCode } from "../WheresMyCodeModal";
import { useActionCreator as useLoginActionCreator } from "./actions/loginActionCreator";
import { useActionCreator } from "./actions/otpActionCreator";
import { Content, passwordlessFlowConstants } from "./dataConstants";
import { shouldShowPhoneNumberInfoBox } from "./PhoneNumberInfoBox/shouldShowPhoneNumberInfoBox";
import PhoneNumberInfoBox from "./PhoneNumberInfoBox";
import ServicesNotificationComponent from "../components/ServicesNotificationComponent";
import { getContactFromSessionStorage } from "../contactSessionStorage";
import { History } from "history";

const ContactWrapper = styled.span`
  font-weight: bold;
  word-break: break-word;
  -ms-word-break: break-all;
`;

type HeadingSubTextProps = {
  contact: string;
  constants: Content;
};

const HeadingSubText = ({ contact, constants }: HeadingSubTextProps) => (
  <Stack space={4}>
    <Copy align="center" size={{ md: "large" }}>
      {constants.HEADER_SUBTEXT_PART_ONE}
      <ContactWrapper>{contact}</ContactWrapper>
    </Copy>
    <Copy align="center" size={{ md: "large" }}>
      {constants.HEADER_SUBTEXT_PART_TWO}
    </Copy>
  </Stack>
);

type CodeEntryProps = {
  state: State;
  action: any;
  constants: Content;
};

const CodeEntry = ({ state, action, constants }: CodeEntryProps) => {
  return (
    <TextBoxComponent
      id="code"
      name="code"
      label={constants?.ENTER_CODE_TEXTBOX_LABEL}
      valid={state.login.otp.isValid}
      validated={state.login.otp.hasBeenValidated}
      error={state.login.otp.validationMessage}
      autoComplete="one-time-code"
      type="text"
      inputMode="decimal"
      pattern="\d*"
      disabled={state.login.otp.verifying}
      onChange={(e) => action.updateCode(e.target.value)}
      onBlur={() => action.validateForm()}
      autoFocus={true}
      value={state.login.otp.code}
    />
  );
};

const alertComponent = (alertState: AlertComponentProps) => {
  return (
    <AlertComponent title={alertState.title} message={alertState.message} type={alertState.type} />
  );
};

const OTP = ({ history }: { history?: History }): JSX.Element => {
  const { state } = useStateValue();
  const config = useConfig();
  const action = useActionCreator();
  const loginAction = useLoginActionCreator();
  const constants = passwordlessFlowConstants(config);
  const [showHelp, setShowHelp] = useState(false);
  useClearAlertsOnLoadHook(state, action);
  const contact = state.login.login.contact;

  useEffect(() => {
    const contact = getContactFromSessionStorage();
    if (contact && !state.login.login.contact) {
      loginAction.contactChanged(contact);
    }

    if (!contact && !state.login.login.contact) {
      // if we do end up here, we should redirect to the login page because we don't have a contact
      history?.replace("/");
    }
  }, []);

  return (
    <BasicPageTemplate>
      <WheresMyCode
        {...{
          showHelp,
          onClose: () => setShowHelp(false),
        }}
      />
      <ContactUsComponent />
      <Header title={constants.ENTER_CODE_HEADER_TITLE}>
        {state.login.otp.alert.type ? (
          alertComponent(state.login.otp.alert)
        ) : (
          <React.Fragment>
            <HeadingSubText contact={contact} constants={constants} />
          </React.Fragment>
        )}
      </Header>
      <ServicesNotificationComponent />
      <DisplayBorder>
        <NoBorderForm
          id="codeEntryForm"
          name="codeEntryForm"
          onSubmit={action.completePasswordlessLogin}
        >
          <CodeEntry
            {...{
              state,
              action,
              constants,
            }}
          />
          {shouldShowPhoneNumberInfoBox(state, config) && <PhoneNumberInfoBox />}
          <Stack space={4}>
            <NoSpacingPrimaryButton
              onClick={() => action.validateForm()}
              data-testid={`ga-inlink-login-verify-otp-next${
                state.login.otp.verifying ? "-spinner" : ""
              }`}
              fullWidth
              type="submit"
              isLoading={state.login.otp.verifying}
              disabled={state.login.otp.verifying}
            >
              {state.login.otp.verifying
                ? constants.ENTER_CODE_SUBMIT_BUTTON_LOADING_TEXT
                : constants.SUBMIT_BUTTON_TEXT}
            </NoSpacingPrimaryButton>

            <Copy align="center" small>
              <LinkButton onClick={() => setShowHelp(true)} type="button">
                Are you having trouble logging in?
              </LinkButton>
            </Copy>
          </Stack>
        </NoBorderForm>
      </DisplayBorder>
    </BasicPageTemplate>
  );
};
export default OTP;

const useClearAlertsOnLoadHook = (state: State, action) =>
  useLayoutEffect(() => {
    if (state.login.otp.alert.type) {
      action.clearAlerts();
    }
    // Passing in empty array ensures that use effect is used once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
