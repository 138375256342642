import React, { JSX, useEffect } from "react";
import { History } from "history";
import { useConfig } from "../ConfigProvider";
import { Login } from "../Login";
import { NibAppPanel } from "./NibAppPanel";
import { appNames } from "../util/appMapping";
import { BasicPageTemplate } from "./BasicPageTemplate";
import { AdvertisingPageTemplate } from "./AdvertisingPageTemplate";
import { removeContactFromSessionStorage } from "../contactSessionStorage";

const allowedMultiPanelApps = [appNames.nibOnlineServicesWeb, appNames.nibOnlineServicesWebTest];

type LoginPanelMasterProps = {
  history: History;
};

export function LoginPanelMaster({ history }: LoginPanelMasterProps): JSX.Element {
  const config = useConfig();

  useEffect(() => {
    // we want to remove this here because this is the entry to the login page and
    // we only want to persist this data for a single login attempt
    removeContactFromSessionStorage();
  }, []);

  return allowedMultiPanelApps.includes(config.currentApp ?? "") ? (
    <AdvertisingPageTemplate
      loginPanel={<Login history={history} isDefaultLogin={false} />}
      advertisingPanel={<NibAppPanel assetBaseUrl={config.assetBaseUrl} />}
    />
  ) : (
    <BasicPageTemplate>
      <Login history={history} />
    </BasicPageTemplate>
  );
}
