import React, { JSX } from "react";
import { LoginPageContainer } from ".";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
`;

type LayoutProps = {
  brand: string;
  children?: JSX.Element | JSX.Element[];
  isProd?: boolean;
  assetBaseUrl?: string;
  padding?: any;
};

const Layout = ({ brand, children, isProd, assetBaseUrl, padding }: LayoutProps) => (
  <div data-testid="page-template-component">
    <LoginPageContainer brand={brand} isProd={isProd} assetBaseUrl={assetBaseUrl} padding={padding}>
      <Wrapper>{children}</Wrapper>
    </LoginPageContainer>
  </div>
);

export default Layout;
