const sessionStorageKey = "login-pages-contact";
const nonceKey = "contact_nonce";

type ContactSessionValue = {
  nonce: string;
  contact: string;
  expires: Date;
};

const generateNonce = () => {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0].toString(36);
};

const removeNonce = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete(nonceKey);
  window.history.replaceState({}, "", url.toString());
};

/**
 * We can use the nonce to uniquely identify this login attempt.
 * If one is not present in the URL, we generate one and add it to the URL.
 * This is to prevent the user from using an old session storage value from a previous login attempt.
 */
const getNonce = (): string => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let nonce = params.get(nonceKey);

  if (!nonce) {
    // add a nonce to the URL
    nonce = generateNonce();
    const url = new URL(window.location.href);
    url.searchParams.set(nonceKey, nonce);
    window.history.replaceState({}, "", url.toString());
  }

  return nonce;
};

export const getContactFromSessionStorage = (): string | null => {
  try {
    const value = sessionStorage.getItem(sessionStorageKey);
    if (!value) {
      return null;
    }
    const parsedValue: ContactSessionValue = JSON.parse(value);
    parsedValue.expires = new Date(parsedValue.expires); // it's a string when stored in session storage

    if (!parsedValue.contact || !parsedValue.expires || !parsedValue.nonce) {
      // eslint-disable-next-line no-console
      console.error("Invalid contact session value");
      removeContactFromSessionStorage();
      return null;
    }

    if (parsedValue.expires < new Date()) {
      removeContactFromSessionStorage();
      return null;
    }

    const nonce = getNonce();
    if (parsedValue.nonce !== nonce) {
      removeContactFromSessionStorage();
      return null;
    }

    return parsedValue.contact ?? null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error getting contact from session storage", e);
    return null;
  }
};

export const setContactInSessionStorage = (contact: string): void => {
  try {
    // 5 mins from now
    const expires = new Date(Date.now() + 5 * 60 * 1000);
    const nonce = getNonce();
    const value: ContactSessionValue = { contact, expires, nonce };
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(value));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error setting contact in session storage", e);

    // we clear the session storage if we can't set the contact to avoid an invalid state
    removeContactFromSessionStorage();
  }
};

export const removeContactFromSessionStorage = (): void => {
  try {
    sessionStorage.removeItem(sessionStorageKey);
    removeNonce();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error removing contact from session storage", e);
  }
};
