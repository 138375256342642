import auth0, { AuthOptions, WebAuth } from "auth0-js";
import { AppConfiguration } from "../ConfigProvider";
import {
  isEmail,
  isPhoneNumber,
  connectionTypeForContact,
  ensurePlus61Format,
  stripSpaces,
} from "./contactUtilities";
import { removeContactFromSessionStorage } from "../contactSessionStorage";

type Auth0PasswordlessLogin = {
  success: boolean;
  reason?: string;
  err?: any;
};

const auth = (config: AuthOptions): auth0.WebAuth => new WebAuth(config);

type DoAuth0PasswordlessLogin = {
  config: AppConfiguration;
  contact: string;
  memberNumber: string;
  code: string;
};

export const doAuth0PasswordlessLogin = ({
  config,
  contact,
  memberNumber,
  code,
}: DoAuth0PasswordlessLogin): Promise<Auth0PasswordlessLogin> => {
  const contactWithoutSpaces = stripSpaces(contact);
  // Response information found here https://auth0.com/docs/api/authentication#post-passwordless-verify
  return new Promise((resolve, reject) => {
    auth(config).passwordlessLogin(
      {
        connection: connectionTypeForContact(contactWithoutSpaces),
        email: isEmail(contactWithoutSpaces) ? contactWithoutSpaces : undefined,
        phoneNumber: isPhoneNumber(contactWithoutSpaces)
          ? ensurePlus61Format(contactWithoutSpaces)
          : undefined,
        verificationCode: code,
        nonce: config.nonce,
        // @ts-ignore - The property is not declared in auth0 props, but kept to prevent breaking compatibility
        memberNumber: isPhoneNumber(contactWithoutSpaces) ? memberNumber : undefined,
        state: config.state,
      },
      (err) => {
        removeContactFromSessionStorage();

        if (!err) {
          return resolve({ success: true });
        }
        // this all matches on multiple options because the response are different in different environments.
        if (
          err.code === "invalid_user_password" ||
          err.error === "invalid_user_password" ||
          err.code === "access_denied" ||
          err.error === "access_denied"
        ) {
          if (
            err.error_description ===
              "You've reached the maximum number of attempts. Please try to login again." ||
            err.description ===
              "You've reached the maximum number of attempts. Please try to login again."
          ) {
            // Matching on description because the error code is the same for bad code and too many codes.
            return resolve({
              success: false,
              reason: responseTypes.accountLocked,
              err,
            });
          }

          return resolve({
            success: false,
            reason: responseTypes.badEmailCodeCombination,
            err,
          });
        }

        if (err.code === "too_many_attempts" || err.error === "too_many_attempts") {
          return resolve({
            success: false,
            reason: responseTypes.accountLocked,
            err,
          });
        }

        return reject({
          success: false,
          reason: responseTypes.unknownError,
          err,
        });
      },
    );
  });
};

export const responseTypes = {
  badEmailCodeCombination: "Bad Email/Code combination",
  accountLocked: "Account Locked",
  unknownError: "unknownError",
};
